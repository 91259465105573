import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ISettlementSupply } from "../../models/ISettlementSupply";
import { ISettlementSupplyPriod } from "../../models/ISettlementSupplyPriod";
import { ISettlementSupplyType } from "../../models/ISettlementSupplyType";
import { IStateSupply } from "../../models/IStateSupply";
import { ISupplyState } from "../../models/ISupply";
import {
    createSettlementSupply,
    getSettlementSupplies,
    getSettlementSuppliesPeriod,
    getSettlementSupplyFile,
    getSettlementSupplyStateById,
    getSettlementSupplyStates,
    getSettlementSupplyTypeById,
    getSettlementSupplyTypes,
} from "../actions/supplies/supplies";

const supplyState: ISupplyState = {
    settlementSupplies: [],
    settlementSuppliesPeriods: [],
    settlementSuppliesTypes: [],
    settlementSupplyStates: [],
    settlementSupplyType: {},
    settlementSupplyState: {},

    settlementFileToUpload: null,

    settlementSupplyId: "",
    settlementPeriodId: "",
    settlementStateId: "",

    isLoadingSettlementSupplies: false,
    isLoadingSettlementSuppliesPeriods: false,
    isLoadingSettlementSupplyType: false,
    isLoadingSettlementSupplyTypeById: false,
    isLoadingSettlementSupplyStateById: false,
    isLoadingSettlementSupplyRequest: false,
    isSettlementSupplyCreated: false,
    isSettlementFileDownloaded: false,

    openLoadFilesModal: false,
    openDeleteFileDialog: false,
};

export const supplySlice = createSlice({
    name: "supplyState",
    initialState: supplyState,
    reducers: {
        setOpenLoadFilesModal: (state, action) => {
            state.openLoadFilesModal = action.payload;
        },
        setOpenDeleteFileDialog: (state, action) => {
            state.openDeleteFileDialog = action.payload;
        },
        setSetttlementSupplies: (state, action) => {
            state.settlementSupplies = action.payload;
        },
        setSettlementSupplyFile: (
            state,
            action: PayloadAction<Blob[] | null>,
        ) => {
            state.settlementFileToUpload = action.payload;
        },
        setIsSettlementSupplyCreated: (state, action) => {
            state.isSettlementSupplyCreated = action.payload;
        },
        clearSettlementSupplies: (state) => {
            state.settlementSupplies = [];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getSettlementSupplies.pending.type, (state) => {
            state.isLoadingSettlementSupplies = true;
        });
        builder.addCase(getSettlementSupplies.rejected.type, (state) => {
            state.isLoadingSettlementSupplies = false;
        });
        builder.addCase(
            getSettlementSupplies.fulfilled.type,
            (state, action: PayloadAction<ISettlementSupply[]>) => {
                state.isLoadingSettlementSupplies = false;
                state.settlementSupplies = action.payload.sort((a, b) => {
                    const dateA = new Date(a.submissionDate ?? "");
                    const dateB = new Date(b.submissionDate ?? "");
                    return dateB.getTime() - dateA.getTime();
                });
            },
        );
        builder.addCase(getSettlementSuppliesPeriod.pending.type, (state) => {
            state.isLoadingSettlementSuppliesPeriods = true;
            state.settlementSuppliesPeriods = [];
        });
        builder.addCase(getSettlementSuppliesPeriod.rejected.type, (state) => {
            state.isLoadingSettlementSuppliesPeriods = false;
        });
        builder.addCase(
            getSettlementSuppliesPeriod.fulfilled.type,
            (state, action: PayloadAction<ISettlementSupplyPriod[]>) => {
                state.isLoadingSettlementSuppliesPeriods = false;
                state.settlementSuppliesPeriods = action.payload;

                if (action.payload.length > 0) {
                    state.settlementPeriodId = action.payload[0].id ?? "";
                }
            },
        );
        builder.addCase(getSettlementSupplyTypes.pending.type, (state) => {
            state.isLoadingSettlementSupplyType = true;
            state.settlementSuppliesTypes = [];
        });
        builder.addCase(getSettlementSupplyTypes.rejected.type, (state) => {
            state.isLoadingSettlementSupplyType = false;
        });
        builder.addCase(
            getSettlementSupplyTypes.fulfilled.type,
            (state, action: PayloadAction<ISettlementSupplyType[]>) => {
                state.isLoadingSettlementSupplyType = false;
                state.settlementSuppliesTypes = action.payload;
            },
        );
        builder.addCase(getSettlementSupplyTypeById.pending.type, (state) => {
            state.isLoadingSettlementSupplyTypeById = true;
            state.settlementSupplyType = {};
        });
        builder.addCase(getSettlementSupplyTypeById.rejected.type, (state) => {
            state.isLoadingSettlementSupplyTypeById = false;
        });
        builder.addCase(
            getSettlementSupplyTypeById.fulfilled.type,
            (state, action: PayloadAction<ISettlementSupplyType>) => {
                state.isLoadingSettlementSupplyTypeById = false;
                state.settlementSupplyType = action.payload;
            },
        );
        builder.addCase(getSettlementSupplyStateById.pending.type, (state) => {
            state.isLoadingSettlementSupplyStateById = true;
            state.settlementSupplyState = {};
        });
        builder.addCase(getSettlementSupplyStateById.rejected.type, (state) => {
            state.isLoadingSettlementSupplyStateById = false;
        });
        builder.addCase(
            getSettlementSupplyStateById.fulfilled.type,
            (state, action: PayloadAction<ISettlementSupplyType>) => {
                state.isLoadingSettlementSupplyStateById = false;
                state.settlementSupplyState = action.payload;
            },
        );
        builder.addCase(createSettlementSupply.pending.type, (state) => {
            state.isLoadingSettlementSupplyRequest = true;
            state.isSettlementSupplyCreated = false;
            state.settlementSupplyState = {};
        });
        builder.addCase(createSettlementSupply.rejected.type, (state) => {
            state.isLoadingSettlementSupplyRequest = false;
            state.isSettlementSupplyCreated = false;
        });
        builder.addCase(
            createSettlementSupply.fulfilled.type,
            (state, action: PayloadAction<ISettlementSupplyType>) => {
                state.openLoadFilesModal = false;
                state.isLoadingSettlementSupplyRequest = false;
                state.settlementSupplyId = action.payload.id ?? "";
                state.isSettlementSupplyCreated = true;
            },
        );

        builder.addCase(getSettlementSupplyFile.pending.type, (state) => {
            state.isSettlementFileDownloaded = true;
        });
        builder.addCase(getSettlementSupplyFile.rejected.type, (state) => {
            state.isSettlementFileDownloaded = false;
        });
        builder.addCase(
            getSettlementSupplyFile.fulfilled.type,
            (state, _action: PayloadAction<ISettlementSupplyType>) => {
                state.isSettlementFileDownloaded = false;
            },
        );

        builder.addCase(
            getSettlementSupplyStates.fulfilled.type,
            (state, action: PayloadAction<IStateSupply[]>) => {
                state.settlementSupplyStates = action.payload;
            },
        );
    },
});

export const {
    setOpenLoadFilesModal,
    setOpenDeleteFileDialog,
    setSetttlementSupplies,
    setSettlementSupplyFile,
    setIsSettlementSupplyCreated,
    clearSettlementSupplies,
} = supplySlice.actions;

export default supplySlice.reducer;
