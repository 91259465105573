import {
  Box,
  NotiProvider,
  Tab,
  Tabs,
  Typography,
  enerbitColors,
} from "@enerbit/base";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  resetCreateOffersAlerts,
  resetPreOffers,
} from "../store/slices/calculator";
import { setEnerbitServiceAgreement } from "../store/slices/makitaSlice";
import { AppDispatch } from "../store/store";
import SearchMakita from "./search/SearchMakita";
import { LoadSupplies } from "./load-supplies/LoadSupplies";
import { mountedDisassembleAppOperationalCosts } from "../utils/utilsSingleSpa";

const SectionInformation = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const dispatch = useDispatch<AppDispatch>();

  const tabs = [
    {
      label: "Administración de planes de energía",
      component: <SearchMakita />,
    },
    {
      label: "Cargue de insumos",
      component: <LoadSupplies />,
    },
    {
      label: "Costos y topes de instalación",
      component: <div id="single-spa-application:app-operational-costs"></div>,
    },
    // {
    //   label: "Creación de pre-ofertas",
    //   component: <OffersCreation />,
    // },
    // {
    //   label: "Carga de documentos",
    //   component: <div></div>,
    // },
  ];

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabIndex(newValue);
  };

  useEffect(() => {
    dispatch(setEnerbitServiceAgreement(null));
    dispatch(resetPreOffers());
    dispatch(resetCreateOffersAlerts());
  }, []);

  useEffect((() => {
    if (selectedTabIndex === 2) {
      mountedDisassembleAppOperationalCosts();
    } 
  }), [selectedTabIndex])


  return (
    <NotiProvider>
      <Box className="Container-makita">
        <Typography
          className="Title-main-information"
          fontWeight="700"
          color={enerbitColors.primary.main}
        >
          Makita
        </Typography>
        <Box sx={{ width: "100%", mt: "20px" }}>
          <Box
            sx={{ borderBottom: 1, borderColor: "#D8D8D8" }}
            className="Box-tabs-main"
          >
            <Tabs
              value={selectedTabIndex}
              onChange={handleChange}
              aria-label="basic tabs example"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#FF7705",
                  color: "black",
                  padding: "0 1rem",
                },
              }}
            >
              {tabs.map(({ label }, i) => (
                <Tab
                  id={`Button-tab-makita-${i + 1}`}
                  className="Custom-tabs"
                  label={label}
                  key={i}
                />
              ))}
            </Tabs>
          </Box>
          {tabs.map((values, indexS) => {
            return (
              <Box
                key={indexS}
                role="tabpanel"
                style={{
                  display: selectedTabIndex != indexS ? "none" : "block",
                }}
                id={`simple-tabpanel-${indexS}`}
                aria-labelledby={`simple-tab-${indexS}`}
              >
                <Box sx={{ paddingBottom: "1.5rem" }}>{values.component}</Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </NotiProvider>
  );
};

export default SectionInformation;
