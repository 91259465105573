import {
  AddIcon,
  Box,
  Button,
  IconButton,
  Pagination,
  SearchIcon,
  TextField,
  Typography,
  useFormik,
  yup,
} from "@enerbit/base";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InitialValues } from "../../models/MakitaSearchState";
import { CustomModalTypes } from "../../models/ModalProps";
import { StateStorage } from "../../models/StateStorage";
import { getEnerbitElectricitySupplyServiceAgreements } from "../../store/actions/electricity-supply-service/electricity-supply-service.actions";
import {
  handlePageChange,
  resetMakitaState,
} from "../../store/slices/makitaSlice";
import { AppDispatch } from "../../store/store";
import { showNotification } from "../../utils/showNotification";
import ListPlansMakita from "../reusable/ListPlansMakita";
import CreateModalPlan from "../reusable/modal/CreateModalPlan";

const SearchMakita = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    planList,
    isLoadingListPlansEnergy,
    page,
    isDoneCreatePlansEnergy,
    errorCreatePlansEnergy,
    successCreatePlansEnergy,
  } = useSelector((state: StateStorage) => state.makitaState);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const createClient = useRef<CustomModalTypes>(null);
  const textInput = useRef<typeof TextField>(null);

  const [hasTyped, setHasTyped] = useState(false);
  const [initialRequestMade, setInitialRequestMade] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    dispatch(resetMakitaState());
  }, [dispatch]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(event);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const value = event.target.value;
    const newTimeoutId = setTimeout(() => {
      if (value.trim() === "") {
        if (hasTyped && !initialRequestMade) {
          dispatchInitialRequest();
        }
        return;
      }
      setHasTyped(true);
      setInitialRequestMade(false);
      dispatchSearchRequest(value);
    }, 500);

    setTimeoutId(newTimeoutId);
  };

  const dispatchInitialRequest = () => {
    dispatch(getEnerbitElectricitySupplyServiceAgreements({ pattern: "" }));
    setInitialRequestMade(true);
  };

  const dispatchSearchRequest = (pattern: string) => {
    dispatch(getEnerbitElectricitySupplyServiceAgreements({ pattern }));
    setInitialRequestMade(false);
  };

  let initialValues: InitialValues = {
    param: "",
  };

  const validationSchema = yup.object({});

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values: InitialValues) => {
      if (values.param.trim() === "") {
        if (hasTyped && !initialRequestMade) {
          dispatchInitialRequest();
        }
        return;
      }
      dispatchSearchRequest(values.param);
    },
  });

  const callCreateClient = () => {
    if (createClient.current) {
      createClient.current.changeModal();
      setIsModalOpen(true);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const _placeholderSearchTextField = (): string => {
    return "Búsqueda por nombre";
  };

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }
    if (
      (successCreatePlansEnergy !== null || errorCreatePlansEnergy !== null) &&
      isModalOpen
    ) {
      showNotification(
        isDoneCreatePlansEnergy,
        successCreatePlansEnergy,
        errorCreatePlansEnergy
      );
    }
  }, [
    isDoneCreatePlansEnergy,
    successCreatePlansEnergy,
    errorCreatePlansEnergy,
    isFirstRender,
  ]);

  return (
    <>
      <Box>
        <Box className="Container-search">
          <Box>
            <Box className="Box-search-makita">
              <Box className="Header-makita">
                <Typography variant="h4">
                  ¿Qué tipo de plan deseas consultar?
                </Typography>
                <Button
                  id="Button-create-plan"
                  name="buttonCreatePlan"
                  color="warning"
                  size="small"
                  className="Loading-button Button-create-plan"
                  onClick={() => callCreateClient()}
                  sx={{ width: "12%", height: "2.8rem" }}
                >
                  <Box className="Button-text-create-plan">
                    <Box>
                      <Box className="Icon-plus-create-plan">
                        <AddIcon className="Icon-item" />
                      </Box>
                    </Box>
                    <Typography
                      variant="button"
                      fontWeight="700"
                      textTransform="none"
                    >
                      Crear
                    </Typography>
                  </Box>
                </Button>
              </Box>
              <Box
                className="TextField-without-border-radius"
                sx={{ marginTop: "40px" }}
              >
                <form onSubmit={formik.handleSubmit}>
                  <TextField
                    id="Input-search-type-plan"
                    name="param"
                    inputRef={textInput}
                    InputProps={{
                      startAdornment: (
                        <IconButton type="submit">
                          <SearchIcon />
                        </IconButton>
                      ),
                    }}
                    placeholder={_placeholderSearchTextField()}
                    onChange={handleSearchChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.param && Boolean(formik.errors.param)}
                    helperText={formik.touched.param && formik.errors.param}
                    sx={{
                      width: "100%",
                      borderRadius: "14px",
                      background: "white",
                    }}
                  />
                </form>
                <CreateModalPlan
                  ref={createClient}
                  onClose={handleModalClose}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <ListPlansMakita />
        <Box
          className="Pagination-tables"
          padding="1rem 0"
          marginBottom="0rem"
          display="flex"
        >
          <Pagination
            className="Pagination-tables"
            count={planList?.pages}
            shape="rounded"
            disabled={
              planList?.pages === 1 || isLoadingListPlansEnergy ? true : false
            }
            page={page + 1}
            sx={{ margin: "0.3rem 0 1rem 0" }}
            onChange={(event: ChangeEvent<unknown>, value: number) => {
              dispatch(handlePageChange(value - 1));
              dispatch(
                getEnerbitElectricitySupplyServiceAgreements({
                  page: value - 1,
                  pattern: formik.values.param,
                })
              );
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default SearchMakita;
