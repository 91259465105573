import { AxiosError } from "axios";
import { IStateSupply } from "../../../../models/IStateSupply";
import { api } from "@enerbit/base";

interface ApiError {
    message: string;
    status: number;
}

export async function getStateSupply(
    stateId: string,
    options?: { signal?: AbortSignal },
): Promise<IStateSupply> {
    try {
        const { data, status } = await api.get(
            `/electricity-supply-service/settlement-supplies/states/get?id=${stateId}`,
            { signal: options?.signal },
        );
        if (status === 200 && data) {
            return data;
        }
        throw new Error("Data not found");
    } catch (error) {
        const axiosError = error as AxiosError;
        throw {
            message: axiosError.message || "Error en la petición",
            status: axiosError.response?.status || 500,
        } as ApiError;
    }
}
