import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ISettlementSupply } from "../../../models/ISettlementSupply";
import { ISettlementSupplyType } from "../../../models/ISettlementSupplyType";
import { SupplyForm } from "../../../pages/load-supplies/components/LoadFilesModal";
import { IStateSupply } from "../../../models/IStateSupply";

export const getSettlementSupplies = createAsyncThunk(
    //action type string
    "[SUPPLIES] Get Settlement Supplies",
    //callback function
    async (
        periodId: string,
        _thunkAPI,
    ): Promise<ISettlementSupply[] | null> => {
        const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        };
        const { data, status } = await api.get(
            `/electricity-supply-service/settlement-supplies/supplies/search?period=${periodId}`,
            { headers },
        );
        if (status === 200 && data) {
            return data["SuppliesList"];
        }
        throw new Error("Data not found");
    },
);

export const getSettlementSuppliesPeriod = createAsyncThunk(
    //action type string
    "[SUPPLIES PERIOD] Get Settlement Supplies Period",
    //callback function
    async (_payload, _thunkAPI): Promise<ISettlementSupply[] | null> => {
        const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        };
        const { data, status } = await api.get(
            `/electricity-supply-service/settlement-supplies/settlement-periods/search?is_active=true`,
            { headers },
        );
        if (status === 200 && data) {
            return data["settlementPeriodsList"];
        }
        throw new Error("Data not found");
    },
);

export const getSettlementSupplyTypes = createAsyncThunk(
    //action type string
    "[SUPPLIES TYPES] Get Settlement Supplies Types",
    //callback function
    async (_payload, _thunkAPI): Promise<ISettlementSupplyType[] | null> => {
        const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        };
        const { data, status } = await api.get(
            `/electricity-supply-service/settlement-supplies/supply-types/search`,
            { headers },
        );
        if (status === 200 && data) {
            return data["SupplyTypeList"];
        }
        throw new Error("Data not found");
    },
);

export const getSettlementSupplyTypeById = createAsyncThunk(
    //action type string
    "[SUPPLIES TYPE] Get Settlement Supplies Type By ID",
    //callback function
    async (id: string, _thunkAPI): Promise<ISettlementSupplyType> => {
        const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        };
        const { data, status } = await api.get(
            `/electricity-supply-service/settlement-supplies/supply-types/get?id=${id}`,
            { headers },
        );
        if (status === 200 && data) {
            return data;
        }
        throw new Error("Data not found");
    },
);

export const getSettlementSupplyStateById = createAsyncThunk(
    //action type string
    "[SUPPLIES TYPE] Get Settlement Supplies State By ID",
    //callback function
    async (id: string, _thunkAPI): Promise<IStateSupply> => {
        const { data, status } = await api.get(
            `/electricity-supply-service/settlement-supplies/states/get?id=${id}`,
        );
        if (status === 200 && data) {
            return data;
        }
        throw new Error("Data not found");
    },
);

export const createSettlementSupply = createAsyncThunk(
    //action type string
    "[SETTLEMENT SUPPLY] Create Settlement Supply",
    //callback function
    async (
        { values, fileToUpload }: { values: SupplyForm; fileToUpload: Blob[] },
        _thunkAPI,
    ): Promise<SupplyForm> => {
        const { data, status } = await api.post(
            `/electricity-supply-service/settlement-supplies/supplies/create`,
            values,
        );
        if (status === 201 && data) {
            const formData = new FormData();
            formData.append("description", "indentificate");
            fileToUpload.forEach((file, _index) => {
                formData.append(`file`, file);
            });
            formData.append("file_type", fileToUpload[0].type);
            formData.append("id", data.id);
            const response = await api.post(
                `/electricity-supply-service/settlement-supplies/tariff`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
            );
            return response.data;
        }
        throw new Error("Data not found");
    },
);

export const getSettlementSupplyFile = createAsyncThunk(
    //action type string
    "[SUPPLIES FILE] Get Settlement Supplies File",
    //callback function
    async (supplyId: string, _thunkAPI): Promise<Blob> => {
        const { data, status } = await api.get(
            `/electricity-supply-service/settlement-supplies/download-file/${supplyId}`,
            {
                responseType: "blob",
            },
        );
        if (status === 200 && data) {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "file.csv");
            document.body.appendChild(link);
            link.click();
        }
        throw new Error("Data not found");
    },
);

export const getSettlementSupplyStates = createAsyncThunk(
    //action type string
    "[SUPPLIES STATES] Get Settlement Supplies States",
    //callback function
    async (_thunkAPI): Promise<IStateSupply[]> => {
        const { data, status } = await api.get(
            `/electricity-supply-service/settlement-supplies/states/search`,
        );
        if (status === 200 && data) {
            return data["StatesList"];
        }
        throw new Error("Data not found");
    },
);
