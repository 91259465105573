import * as singleSpa from "single-spa";

const mountedAppSingleSpaOperationalCosts = () => {
    singleSpa.registerApplication(
        "app-operational-costs",
        (): Promise<singleSpa.LifeCycles> =>
            System.import("@enerBit/operational-costs"),
        (location) => location.pathname.startsWith("/"),
    );
};

export const mountedDisassembleAppOperationalCosts = async () => {
    const appOperationalCosts = singleSpa.getAppNames().find((item) => item == "app-operational-costs");
  
    if (appOperationalCosts) {
      singleSpa
        .unregisterApplication("app-operational-costs")
        .then(() => mountedAppSingleSpaOperationalCosts());
    } else {
        mountedAppSingleSpaOperationalCosts();
    }
  };
  