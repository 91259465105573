import {
  Box,
  DownloadOutlinedIcon,
  LoadingButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  enerbitColors,
} from "@enerbit/base";

import moment from "moment";
import { ISettlementSupply } from "../../../models/ISettlementSupply";
import { SupplyState } from "./SupplyState";
import { SupplyType } from "./SupplyType";
import { useDispatch, useSelector } from "react-redux";
import { getSettlementSupplyFile } from "../../../store/actions/supplies/supplies";
import { AppDispatch } from "../../../store/store";
import { StateStorage } from "../../../models/StateStorage";
import { useEffect, useState } from "react";

export type SettlementSuplyProps = {
  settlementSuplies: ISettlementSupply[];
};

export const SupplyTable = ({ settlementSuplies }: SettlementSuplyProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [sortedSupplies, setSortedSupplies] = useState<ISettlementSupply[]>([]);

  const { isSettlementFileDownloaded } = useSelector(
    (state: StateStorage) => state.supplyState
  );

  useEffect(() => {
    const sorted = [...settlementSuplies].sort((a, b) => {
      const dateA = a.createdAt ? new Date(a.createdAt) : new Date();
      const dateB = b.createdAt ? new Date(b.createdAt) : new Date();
      return dateB.getTime() - dateA.getTime();
    });
    setSortedSupplies(sorted);
  }, [settlementSuplies]);
  return (
    <TableContainer>
      <Table
        className="Table-makita"
        sx={{ minWidth: 650 }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell align="left">Equipo</TableCell>
            <TableCell align="left">Insumo</TableCell>
            <TableCell align="left">Fecha límite</TableCell>
            <TableCell align="left">Fecha entrega</TableCell>
            <TableCell align="left">Notas importantes</TableCell>
            <TableCell align="left">Estado</TableCell>
            <TableCell align="center">Archivo</TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          style={{
            borderRadius: "15px",
            overflow: "hidden",
            backgroundColor: "transparent", // Usando style directamente
          }}
        >
          {settlementSuplies != null ? (
            sortedSupplies.map((supply, index) => (
              <TableRow key={index} sx={{ height: "5.5rem" }}>
                <TableCell align="left">{supply.liableTeam}</TableCell>
                <TableCell align="left">
                  <SupplyType supplyId={supply.supplyTypeId || ""} />
                </TableCell>
                <TableCell align="left">
                  {moment(supply.deadline).format("DD MMMM YYYY")}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    color: enerbitColors.success.main,
                    "&.MuiTableCell-root": {
                      color: enerbitColors.success.main,
                    },
                  }}
                >
                  {moment(supply.createdAt).format("DD MMMM YYYY")}
                </TableCell>
                <TableCell align="left">{supply.observations ?? ""}</TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  <SupplyState supplyStateId={supply.stateId!} />
                </TableCell>
                <TableCell align="center">
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <LoadingButton
                      id={supply.id}
                      loading={isSettlementFileDownloaded}
                      loadingPosition="center"
                      variant="contained"
                      className="Loading-button Button-download-files"
                      sx={{
                        width: "10%",
                        height: "1.5rem",
                      }}
                      onClick={(event) => {
                        const buttonId = event.currentTarget.id;
                        if (buttonId) {
                          dispatch(getSettlementSupplyFile(buttonId));
                        }
                      }}
                    >
                      {!isSettlementFileDownloaded && (
                        <Box className="Button-text-download-files">
                          <Box>
                            <Box className="Icon-load-supplies">
                              <DownloadOutlinedIcon className="Icon-item-files" />
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </LoadingButton>

                    {/* // <Button
                                        //     id="Button-download-files"
                                        //     name="Button-download-files"
                                        //     color="warning"
                                        //     size="small"
                                        //     className="Loading-button Button-download-files"
                                        //     onClick={() => {}}
                                        //     loading={false}
                                        //     sx={{
                                        //         width: "10%",
                                        //         height: "1.5rem",
                                        //     }}
                                        // >
                                        //     <Box className="Button-text-download-files">
                                        //         <Box>
                                        //             <Box className="Icon-load-supplies">
                                        //                 <DownloadOutlinedIcon className="Icon-item-files" />
                                        //             </Box>
                                        //         </Box>
                                        //     </Box>
                                        // </Button> */}

                    {/* <Button
                                            id="Button-view-files"
                                            name="Button-view-files"
                                            color="warning"
                                            size="small"
                                            className="Loading-button Button-view-files"
                                            onClick={() => {}}
                                            sx={{
                                                width: "10%",
                                                height: "1.5rem",
                                            }}
                                        >
                                            <Box className="Button-text-view-files">
                                                <Box>
                                                    <Box
                                                        className="Icon-load-supplies"
                                                        sx={{
                                                            backgroundColor:
                                                                enerbitColors
                                                                    .primary
                                                                    .main,
                                                        }}
                                                    >
                                                        <RemoveRedEyeOutlinedIcon className="Icon-item-files" />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Button> */}
                  </Box>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <Box />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
