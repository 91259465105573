import { Socket } from "@enerbit/base/common/utils/socket";
import { setSetttlementSupplies } from "../store/slices/supply.slice";

export const socketMiddleware =
    (socket: Socket) =>
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    (params: { dispatch: any; getState: any }) =>
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    (next: (arg0: any) => any) =>
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    (action: { type: any }) => {
        const { dispatch } = params;
        const { type } = action;

        switch (type) {
            case "socket/connect":
                socket.connect(
                    process.env.REACT_APP_WS_BASE_URL +
                        "/electricity-supply-service/settlement-supplies/get-supplies-status",
                );
                socket.on("open", () => {});
                socket.on("message", (ev: Event) => {
                    const event = ev as MessageEvent;
                    let data = JSON.parse(event.data);
                    console.log("data from socket", data);
                    dispatch(setSetttlementSupplies(data["SuppliesList"]));
                });
                socket.on("close", () => {});
                socket.on("error", () => {});
                break;

            case "socket/disconnect":
                socket.disconnect();
                break;

            default:
                break;
        }

        return next(action);
    };
