import { AxiosError } from "axios";
import { api } from "@enerbit/base";
import { ISettlementSupply } from "../../../../models/ISettlementSupply";

interface ApiError {
    message: string;
    status: number;
}

export async function getSupplies(
    periodId: string,
    options?: { signal?: AbortSignal },
): Promise<ISettlementSupply[]> {
    try {
        const { data, status } = await api.get(
            `/electricity-supply-service/settlement-supplies/supplies/search?period=${periodId}`,
            { signal: options?.signal },
        );
        if (status === 200 && data) {
            return data["SuppliesList"];
        }
        throw new Error("Data not found");
    } catch (error) {
        const axiosError = error as AxiosError;
        throw {
            message: axiosError.message || "Error en la petición",
            status: axiosError.response?.status || 500,
        } as ApiError;
    }
}
