import { Box, Chip, CircularProgress, enerbitColors } from "@enerbit/base";
import { useEffect, useState } from "react";
import { IStateSupply } from "../../../models/IStateSupply";
import { getStateSupply } from "./service/supply_state";

interface SupplyStateProps {
  backgroundColor?: string;
  textColor?: string;
}

export const SupplyState = ({ supplyStateId }: { supplyStateId: string }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [supplyState, setSupplyState] = useState<IStateSupply>();

  const [_error, setError] = useState<string | null>(null);

  useEffect(() => {
    const controller = new AbortController();

    const fetchData = async () => {
      try {
        setIsLoading(true);
        setError(null);

        const data = await getStateSupply(supplyStateId, {
          signal: controller.signal,
        });
        setSupplyState(data);
      } catch (_err) {
        if (!controller.signal.aborted) {
          setError("Error al cargar el estado");
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (supplyStateId) {
      fetchData();
    }

    return () => controller.abort();
  }, [supplyStateId]);

  function getColorForSettlementSupplyState(state: string): SupplyStateProps {
    let backgroundColor: string;
    let textColor: string;
    switch (state) {
      case "Empty":
        backgroundColor = "grey";
        textColor = enerbitColors.neutral[700] ?? "";
        break;
      case "Blob Uploaded":
        backgroundColor = enerbitColors.primary[200] ?? "";
        textColor = enerbitColors.primary.main ?? "";
        break;
      case "Sended":
        backgroundColor = enerbitColors.warning[100] ?? "";
        textColor = enerbitColors.warning[600] ?? "";
        break;
      case "Processing":
        backgroundColor = enerbitColors.warning[100] ?? "";
        textColor = enerbitColors.warning[600] ?? "";
        break;
      case "Failed":
        backgroundColor = enerbitColors.error[200] ?? "";
        textColor = enerbitColors.error[600] ?? "";
        break;
      case "Creating":
        backgroundColor = enerbitColors.information[200] ?? "";
        textColor = enerbitColors.information[600] ?? ""; // Cambiando el color del texto a blanco para un fondo rojo
        break;
      case "Uploaded":
        backgroundColor = enerbitColors.secondary_green[200] ?? "";
        textColor = enerbitColors.secondary_green[600] ?? ""; // Cambiando el color del texto a blanco para un fondo rojo
        break;
      default:
        backgroundColor = "white";
        textColor = "black";
        break;
    }

    return { backgroundColor, textColor };
  }

  const { backgroundColor, textColor } = getColorForSettlementSupplyState(
    supplyState?.state || "Empty"
  );

  return (
    <Box>
      {isLoading ? (
        <Box className="Loading-flex" sx={{ margin: 0 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Chip
          label={supplyState?.description || "Sin estado"}
          style={{
            color: textColor,
            backgroundColor: backgroundColor,
          }}
        />
      )}
    </Box>
  );
};
