import {
  Autocomplete,
  Box,
  Button,
  CardPdf,
  DragZone,
  FormGroup,
  FormikType,
  Grid,
  HighlightOffIcon,
  IconButton,
  InputLabel,
  LoadingButton,
  Modal,
  TextField,
  Typography,
  enerbitColors,
  useFormik,
} from "@enerbit/base";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { StateStorage } from "../../../models/StateStorage";
import { AppDispatch } from "../../../store/store";

import { useCallback, useEffect, useState } from "react";
import { createSettlementSupply } from "../../../store/actions/supplies/supplies";
import {
  setOpenLoadFilesModal,
  setSettlementSupplyFile,
} from "../../../store/slices/supply.slice";
import { resetFormValues } from "../../../store/slices/supplyForm.slice";
import { DeleteFileDialog } from "./DeleteFileDialog";

export type FileBlobType = Blob | any;

export interface SupplyForm {
  liable_team: string;
  supply_type_id: string;
  settlement_period_id: string;
  deadline: string;
  submission_date: string;
  observations: string;
  state_id: string;
  blob: string;
}

export const LoadFilesModal = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [fileInfo, setFileInfo] = useState<Blob>();
  const [isSuccess, _setIsSuccess] = useState(false);
  const [progress, _setProgress] = useState(0);

  const onClearFile = (): void => {
    setIsLoadingFile(false);
    formik.setFieldValue("blob", "");
    // dispatch(setOpenDeleteFileDialog(true));
  };

  const {
    openLoadFilesModal,
    isLoadingSettlementSupplyType,
    isLoadingSettlementSupplyRequest,
    settlementSuppliesTypes,
    settlementFileToUpload,
    isSettlementSupplyCreated,
    settlementPeriodId,
    settlementSupplyStates,
  } = useSelector((state: StateStorage) => state.supplyState);

  useEffect(() => {
    formik.setFieldValue("settlement_period_id", settlementPeriodId);
    const findSendedState = settlementSupplyStates.find(
      (supplyState) => supplyState.state === "Sended"
    );
    formik.setFieldValue("state_id", findSendedState?.id);

    if (isSettlementSupplyCreated) {
      formik.resetForm();
      setIsLoadingFile(false);
      dispatch(resetFormValues());
    }
  }, [isSettlementSupplyCreated]);

  const onDrop = useCallback((supplyFile: Blob[]) => {
    formik.setFieldValue("blob", supplyFile[0].name);
    setFileInfo(supplyFile[0]);
    dispatch(setSettlementSupplyFile(supplyFile));
    setIsLoadingFile(true);
  }, []);

  const teams = [{ label: "MEM", id: "1" }];

  const initialState = useSelector(
    (state: StateStorage) => state.supplyFormState
  );

  const validationSchema = Yup.object({
    liable_team: Yup.string().required("Este campo es obligatorio"),
    supply_type_id: Yup.string().required("Este campo es obligatorio"),
    settlement_period_id: Yup.string().required("Este campo es obligatorio"),
    blob: Yup.string().required("Debes subir un archivo"),
    observations: Yup.string().required("Este campo es obligatorio"),
  });

  const formik: FormikType<SupplyForm> = useFormik<SupplyForm>({
    initialValues: initialState,
    validationSchema: validationSchema,
    onSubmit: (values: SupplyForm) => {
      dispatch(
        createSettlementSupply({
          values,
          fileToUpload: settlementFileToUpload || [],
        })
      );
    },
  });

  return (
    <Modal open={openLoadFilesModal}>
      <Box className="Modal-load-files">
        <Box className="Modal-header-load-files">
          <Typography
            color={enerbitColors.primary.main}
            align="center"
            fontWeight="bold"
            fontSize={28}
          >
            Subir insumos
          </Typography>
          <IconButton
            onClick={(_) => {
              dispatch(resetFormValues());
              dispatch(setOpenLoadFilesModal(false));
            }}
          >
            <HighlightOffIcon />
          </IconButton>
        </Box>
        <DeleteFileDialog />
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormGroup>
                  <InputLabel
                    shrink
                    className="Input-label"
                    sx={{ fontSize: "18px" }}
                  >
                    ¿Quién envía el insumo?
                  </InputLabel>
                  <Autocomplete
                    id="liable-team"
                    options={teams}
                    onChange={(_event, value) => {
                      formik.setFieldValue("liable_team", value?.label || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="liable_team"
                        className="TextField-without-border-radius"
                        variant="outlined"
                        onBlur={formik.handleBlur}
                        inputProps={{
                          ...params.inputProps,
                        }}
                        error={
                          formik.touched.liable_team &&
                          Boolean(formik.errors.liable_team)
                        }
                        helperText={
                          formik.touched.liable_team &&
                          formik.errors.liable_team
                        }
                      />
                    )}
                    value={teams.find(
                      (value) => value.id == formik.values.liable_team
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <InputLabel
                    shrink
                    className="Input-label"
                    sx={{ fontSize: "18px" }}
                  >
                    Elige el insumo que enviarás
                  </InputLabel>

                  <Autocomplete
                    id="combo-box-demo"
                    loading={isLoadingSettlementSupplyType}
                    options={settlementSuppliesTypes}
                    getOptionLabel={(option) => {
                      if (option) {
                        return `${option.supplyType} - ${option.description}`;
                      }
                      return "";
                    }}
                    value={
                      settlementSuppliesTypes.find(
                        (value) => value.id == formik.values.supply_type_id
                      ) ?? null
                    }
                    onChange={(_event, value) => {
                      formik.setFieldValue("supply_type_id", value?.id);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="marketer"
                        className="TextField-without-border-radius"
                        variant="outlined"
                        onBlur={formik.handleBlur}
                        inputProps={{
                          ...params.inputProps,
                        }}
                        error={
                          formik.touched.supply_type_id &&
                          Boolean(formik.errors.supply_type_id)
                        }
                        helperText={
                          formik.touched.supply_type_id &&
                          formik.errors.supply_type_id
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              {/* <Grid item xs={6}>
                <FormGroup>
                  <InputLabel
                    shrink
                    className="Input-label"
                    sx={{ fontSize: "18px" }}
                  >
                    Sitio de publicación
                  </InputLabel>
                  <TextField
                    fullWidth
                    className="TextField-without-border-radius"
                    variant="outlined"
                    multiline
                    placeholder="Ingresa la información"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <InputLabel
                    shrink
                    className="Input-label"
                    sx={{ fontSize: "18px" }}
                  >
                    ¿Es opción tarifaria?
                  </InputLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="secondary"
                        name="contributionFlag"
                        onBlur={formik.handleBlur}
                        checked={true}
                        onChange={formik.handleChange}
                      />
                    }
                    label={
                      <Typography color={enerbitColors.neutral.main}>
                        Seleccione si aplica
                      </Typography>
                    }
                  />
                </FormGroup>
              </Grid> */}
              <Grid item xs={12}>
                <FormGroup>
                  <InputLabel
                    shrink
                    className="Input-label"
                    sx={{ fontSize: "18px" }}
                  >
                    <span className="bold">Sube tu archivo,</span> recuerda que
                    debe estar en formato CSV
                  </InputLabel>
                  {isLoadingFile ? (
                    <CardPdf
                      typeFile="csv"
                      fileInfo={fileInfo as FileBlobType}
                      progress={progress}
                      isSuccess={isSuccess}
                      onClearFile={onClearFile}
                      isClearFile={true}
                    />
                  ) : (
                    <DragZone
                      onDrop={onDrop as FileBlobType}
                      typeFile="csv"
                      error={formik.touched.blob && Boolean(formik.errors.blob)}
                    />
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <InputLabel
                    shrink
                    className="Input-label"
                    sx={{ fontSize: "18px" }}
                  >
                    <span className="bold">Ingresa las observaciones</span> que
                    consideres relevantes para el equipo de facturación.
                  </InputLabel>
                  <TextField
                    fullWidth
                    className="TextField-without-border-radius"
                    variant="outlined"
                    multiline
                    placeholder="Ingresa cualquier nota u observación"
                    name="observations"
                    value={formik.values.observations}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.observations &&
                      Boolean(formik.errors.observations)
                    }
                    helperText={
                      formik.touched.observations && formik.errors.observations
                    }
                    inputProps={{ maxLength: 140 }}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  onClick={(_) => {
                    formik.resetForm();
                    dispatch(resetFormValues());
                    dispatch(setOpenLoadFilesModal(false));
                  }}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item xs={6}>
                <LoadingButton
                  fullWidth
                  variant="contained"
                  color="secondary"
                  type="submit"
                  loading={isLoadingSettlementSupplyRequest}
                >
                  Enviar
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};
